body {
  background-color: #fff;
  font-family: 'Arial', sans-serif;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.loading-container {
  height: 100vh;
  background-color: #f8f9fa;
}

.logo {
  width:400px;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* src/components/ProductList.css */

/* Contenedor de la imagen del producto */
.product-image-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Imagen del producto */
.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen no se deforme */
}

/* Estilos para el modal de zoom */
.zoomed-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:80vh; /* Ocupa toda la altura de la pantalla */
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro */
}

/* Imagen ampliada en el modal */
.zoomed-image {
  max-width: 100%;  /* No supera el ancho del dispositivo */
  max-height: 100vh; /* No supera la altura del dispositivo */
  object-fit: contain; /* Mantiene las proporciones de la imagen */
}